import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import NumberFormat from 'react-number-format';

import { BulkRetryFilters, BulkRetryModels, bulk_retry_models_label } from '.';
import { APIBatchOperation } from '../../../../../../typings/BatchOperation.interface';
import APIMethodKeys from '../../../client/APIMethodKeys';
import { EventListFiltersProps } from '../../../typings/EventList.interface';
import { RequestListFiltersProps } from '../../../typings/RequestList.interface';
import { GlobalContext } from '../../contexts/GlobalContext';
import { formatEventFiltersForAPI } from '../../hooks/useEventList';
import { formatRequestFiltersForAPI } from '../../hooks/useRequestList';
import Alert from '../base/Alert';
import Button from '../base/Button';
import { StyledCardSection } from '../base/Card';
import Icon from '../base/Icon';
import Link from '../base/Link';
import Loading from '../base/Loading';
import Text from '../base/Text';
import { Div } from '../helpers/StyledUtils';
import { useToasts } from '../Toast';
import BulkRetryBreakdown from './BulkRetryBreakdown';
import BulkRetryProgress from './BulkRetryProgress';
import LINKS from '../../../configs/links';

const cleanupQueryForBulkRetry = (query: BulkRetryFilters) => {
  query = { ...query };
  'next' in query && delete query.next;
  'prev' in query && delete query.prev;
  return query;
};

const models_query_formatters: Record<
  BulkRetryModels,
  (
    filters: BulkRetryFilters,
    order_by: string,
  ) => {
    [k: string]: any;
  }
> = {
  events: formatEventFiltersForAPI,
  requests: formatRequestFiltersForAPI,
  ignored_events: (filters) => filters,
};

const SubmitBulkRetry: React.FC<{
  model: BulkRetryModels;
  query: BulkRetryFilters;
  revalidateCounts: () => void;
  onSubmit?: () => void;
}> = ({ model, query, onSubmit, revalidateCounts }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const prev_bulk_retry = useRef<APIBatchOperation | null>(null);
  const [submitted_bulk_retry, setSubmittedBulkRetry] = useState<APIBatchOperation | null>(null);
  const [pending, setPending] = useState(false);
  const { addToast } = useToasts();
  const cleaned_query = useMemo(() => cleanupQueryForBulkRetry(query), [query]);
  const api_query = useMemo(
    () => models_query_formatters[model](cleaned_query, 'created_at'),
    [cleaned_query],
  );

  const has_no_filters =
    Object.values(cleaned_query).filter((v) => {
      if ((Array.isArray(v) && v.length === 0) || v === null || v === undefined) {
        return false;
      }
      if (typeof v === 'object') {
        return Object.values(v).some((v) => !!v);
      }
      return !!v;
    }).length === 0;

  const { data: plan } = useSWR(
    !has_no_filters && APIMethodKeys.bulk[model].plan({ query: api_query }),
    () => HookdeckAPI.bulk[model].plan({ query: api_query }),
  );

  const { data: current_bulk_retries } = useSWR(
    APIMethodKeys.bulk[model].list(
      submitted_bulk_retry
        ? { id: submitted_bulk_retry.id }
        : { query: api_query, in_progress: true },
    ),
    () =>
      HookdeckAPI.bulk[model].list(
        submitted_bulk_retry
          ? { id: submitted_bulk_retry.id }
          : { query: api_query, in_progress: true },
      ),
    { refreshInterval: 5000 },
  );

  const current_bulk_retry =
    (current_bulk_retries?.models &&
      current_bulk_retries?.models.length > 0 &&
      current_bulk_retries?.models[0]) ||
    submitted_bulk_retry;

  useEffect(() => {
    if (
      prev_bulk_retry.current?.in_progress === true &&
      (current_bulk_retry?.in_progress === false || !current_bulk_retry)
    ) {
      revalidateCounts();
    }
    prev_bulk_retry.current = current_bulk_retry;
  }, [current_bulk_retry]);

  const handleRetry = () => {
    setPending(true);
    HookdeckAPI.bulk[model]
      .retry({ query: models_query_formatters[model](query, 'created_at') })
      .then((bulk_retry) => {
        setSubmittedBulkRetry(bulk_retry);
        addToast('success', `${bulk_retry_models_label[model]} queued for retry`);
        setPending(false);
        revalidateCounts();
        if (onSubmit) {
          onSubmit();
        }
      })
      .catch(() => {
        addToast('error', `${bulk_retry_models_label[model]} failed to queue for retry`);
        setPending(false);
      });
  };

  if (!current_bulk_retries) {
    return (
      <StyledCardSection p={5}>
        <Loading />
      </StyledCardSection>
    );
  }

  return (
    <>
      <StyledCardSection p={4}>
        {!has_no_filters && (
          <Text m={{ b: 3 }}>
            Retry {bulk_retry_models_label[model].toLowerCase()} matching the following conditions:
          </Text>
        )}
        <BulkRetryBreakdown query={api_query} model={model} />
        {has_no_filters && (
          <Alert info inline>
            Apply at least 1 filters to retry {model}.
          </Alert>
        )}
        {model === 'requests' &&
          !has_no_filters &&
          (query as RequestListFiltersProps).status !== 'rejected' && (
            <Alert info inline m={{ t: 2 }}>
              Accepted requests cannot be retried and are excluded from the count.{' '}
              <Link href={LINKS.product_docs.request_retries}>Learn more</Link>
            </Alert>
          )}
        {model === 'events' &&
          ((query as EventListFiltersProps).status?.includes('pending') ||
            !(query as EventListFiltersProps).status ||
            (query as EventListFiltersProps).status!.length === 0) && (
            <Alert info inline m={{ t: 2 }}>
              Events already pending retry are excluded from bulk retry count.
            </Alert>
          )}
      </StyledCardSection>
      <StyledCardSection p={{ x: 4, y: 3 }}>
        {!has_no_filters && current_bulk_retry ? (
          <BulkRetryProgress
            model={model}
            bulk_retry={current_bulk_retry}
            onCancel={(bulk_retry) => {
              setSubmittedBulkRetry(bulk_retry);
              revalidateCounts();
            }}
          />
        ) : (
          <Div flex={{ justify: 'space-between', align: 'center' }}>
            {!has_no_filters ? (
              <Text heading>
                <NumberFormat
                  renderText={(v) => v}
                  displayType="text"
                  value={plan?.estimated_count || 0}
                  thousandSeparator={','}
                />{' '}
                {bulk_retry_models_label[model]}
              </Text>
            ) : (
              <span />
            )}
            <Button.Permission
              role="member"
              onClick={handleRetry}
              disabled={plan?.estimated_count === 0 || pending || has_no_filters}>
              {pending ? <Icon icon="loading" /> : 'Retry All'}
            </Button.Permission>
          </Div>
        )}
      </StyledCardSection>
    </>
  );
};

export default SubmitBulkRetry;
